<template>
  <div class="participant-profile">
    <ValidationObserver v-slot="{ errors }">
      <ParticipantHeader
        :participant="participant"
        :loading="$apollo.queries.participant.loading"
        :is-e-d-c-active="isEDCActive"
      >
        <div
          v-if="isEDCActive"
          class="participant-header__actions"
        >
          <el-button
            :loading="loading || $apollo.queries.participant.loading"
            class="participant__edit-profile"
            :type="editing || loading ? 'primary' : 'outline'"
            :disabled="editing && invalid(errors)"
            @click="toggleEditingMode"
          >
            <span v-if="!loading">{{ editing ? 'Save Changes' : 'Edit Participant Profile' }}</span>
          </el-button>
        </div>
      </ParticipantHeader>

      <NotificationTray
        ref="notifications"
        :notifications="notifications"
      />

      <ParticipantStudies
        :participant="participant"
      />

      <DataForm
        v-model="participantWithCalculatedFields"
        v-loading="loading || $apollo.queries.participant.loading"
        :sections="sections"
        :editing="editing"
      />

      <section
        v-if="isSubstudiesSupported"
        class="sub-studies"
      >
        <h3>Sub-studies</h3>
        <SubstudySelector
          :editable-participant-data="participantWithCalculatedFields"
          :visits="visits"
          :disabled="!editing"
          :profile-view="true"
          @toggle-substudies="toggleSubstudies"
        />
      </section>
    </ValidationObserver>

    <Modal
      v-if="unsavedChangesModalVisible"
      title="Unsaved changes"
      @close="returnToProfile"
    >
      <template v-slot:content>
        Are you sure you want to close this form? If you continue, your changes will not be saved.
      </template>
      <template v-slot:actions>
        <el-button
          type="primary"
          @click="returnToProfile"
        >
          Return to Profile
        </el-button>
        <el-button
          type="outline"
          @click="continueWithoutSaving"
        >
          Continue
        </el-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import ParticipantHeader from '@/components/PageHeader/ParticipantHeader'
import NotificationTray from '@/components/NotificationTray'
import DataForm from '@/components/DataForm/DataForm'
import SubstudySelector from '@/components/SubstudySelector/SubstudySelector'
import Modal from '@/components/Modal/Modal'
import detectModule from '@/mixins/detectModule'
import participant from '@/mixins/queries/participant'
import sites from '@/mixins/queries/sites'
import cohorts from '@/mixins/queries/cohorts'
import substudies from '@/mixins/queries/substudies'
import participantVisits from '@/mixins/queries/participantVisits'
import updateParticipant from '@/mixins/mutations/updateParticipant'
import linkParticipant from '@/mixins/mutations/linkParticipant'
import updateSubstudies from '@/mixins/mutations/updateSubstudies'
import { formatVisits, getActiveVisit, isScreeningVisit, isBaselineVisit } from '@/utils/visit'
import {
  calculateScheduleWeight,
  getCohortSubgroups,
  isProdromalCohort,
  findHierarchyByWeight,
  formatSubgroupsForMultiselectOptions,
  formatSubgroupsForMultiselectValues
} from '@/utils/cohorts'
import ParticipantStudies from '@/components/ParticipantStudies/ParticipantStudies'
import { formatDateForAPI } from '@/utils/date'
import { ParticipantProtocolStatus, VisitStatus } from '@/utils/constants'
import { countryOptions } from '@/utils/countries'
import { logError } from '@/utils/logging'
import { compose, equals, propOr, sortBy, toUpper, omit } from 'ramda'

export default {
  components: {
    ParticipantHeader,
    NotificationTray,
    DataForm,
    SubstudySelector,
    ParticipantStudies,
    Modal
  },
  mixins: [
    detectModule,
    participant,
    sites,
    cohorts,
    participantVisits,
    updateParticipant,
    linkParticipant,
    substudies,
    updateSubstudies
  ],
  // Setup route guards
  beforeRouteUpdate(to, from, next) {
    this.navigationGuard(next)
  },
  beforeRouteLeave(to, from, next) {
    this.navigationGuard(next)
  },
  data() {
    return {
      editing: false,
      loading: false,
      notifications: [],
      participantWithCalculatedFields: {},
      unsavedChangesModalVisible: false,
      routerNext: null
    }
  },
  computed: {
    /**
     * Setup the sections used within the data form.
     * @returns {array} - list of form fields to include in the modal
     */
    sections() {
      const _sections = []
      if (this.isEDCActive) {
        _sections.push(this.personalInformationSection)
      }
      _sections.push(this.studyDetailsSection)
      return _sections
    },

    /**
     * Setup the "Personal Information" section.
     * @returns {object}
     */
    personalInformationSection() {
      const birthDateField = {
        id: 'birthDate',
        label: 'Date of Birth',
        editable: false,
        type: 'date',
        isLocked: true
      }

      return {
        title: 'Personal Information',
        fields: [
          {
            id: 'firstName',
            label: 'First Name',
            type: 'text',
            validationRules: 'required'
          },
          {
            id: 'middleName',
            label: 'Middle Name',
            type: 'text'
          },
          {
            id: 'familyName',
            label: 'Last Name',
            type: 'text',
            validationRules: 'required'
          },
          {
            id: 'emailAddress',
            label: 'Email Address',
            type: 'text',
            validationRules: 'email'
          },
          birthDateField,
          {
            id: 'sex',
            label: 'Sex',
            type: 'select',
            editable: false,
            isLocked: true,
            possibleValues: [
              { label: 'Female', value: 'female_not_of_childbearing_potential' },
              { label: 'Female', value: 'female_of_childbearing_potential' },
              { label: 'Male', value: 'male' }
            ]
          },
          ...(this.participant.sex.startsWith('female') ? [
            {
              id: 'childbearingPotential',
              label: 'Is participant of childbearing potential?',
              type: 'select',
              possibleValues: [
                { label: 'Yes', displayValue: 'Yes', value: true },
                { label: 'No', displayValue: 'No', value: false }
              ],
              // we don't allow users to just choose "female" anymore, but allow for legacy participants
              // to only have female selected without requiring childbearing potential
              validationRules: this.participant.sex !== 'female' ? 'required' : '',
              popperClass: 'childbearing-potential-dropdown',
              visible: false
            }
          ] : []),
          {
            id: 'birthCity',
            label: 'City/Municipality of Birth',
            type: 'text'
          },
          {
            id: 'birthCountry',
            label: 'Country of Birth',
            type: 'select',
            possibleValues: countryOptions
          }
        ]
      }
    },

    /**
     * Setup the "Study Details" section.
     * @returns {object}
     */
    studyDetailsSection() {
      const studyDetailsSection = {
        title: 'Study Details',
        fields: [
          {
            id: 'customerDefinedId',
            label: 'Participant ID',
            type: 'text',
            editable: false
          },
          {
            id: 'status',
            label: 'Status',
            type: 'text',
            editable: false
          },
          {
            id: 'cohortId',
            label: 'Cohort',
            type: 'select',
            editable: this.canEditCohort,
            possibleValues: this.cohortsOptions,
            validationRules: 'required',
            isLocked: !this.canEditCohort,
            popperClass: 'cohort-dropdown'
          },
          {
            id: 'gdprConsentDate',
            label: 'Date GDPR Consent Signed',
            type: 'date',
            placeholder: 'Select a Date',
            'disable-before': '4/3/2020', // study start date
            'disable-after': new Date() // today
          }
        ]
      }

      // If the cohort can be edited, include a confirmation field.
      if (this.canEditCohort) {
        studyDetailsSection.fields.push({
          id: 'confirmCohortId',
          label: 'Confirm Cohort',
          type: 'select',
          editable: true,
          possibleValues: this.cohortsOptions,
          validationRules: 'required|sameAsCohort:@cohortIdValidation',
          isLocked: false,
          isConfirmation: true,
          popperClass: 'confirm-cohort-dropdown'
        })
      }

      // if available, display the enrollment subgroups field.
      if (this.displaySubgroupsField) {
        // find the subgroup with the "must-be-only" validation to setup the field's validation.
        const mustBeOnlySubgroup = this.subgroupOptions.find(subgroup => subgroup.mustBeOnly)
        studyDetailsSection.fields.push({
          id: 'subgroups',
          type: 'multiselect',
          label: 'Subgroups',
          instructions: this.canEditSubgroups && this.editing ? 'Select all that apply.' : '',
          possibleValues: formatSubgroupsForMultiselectOptions(this.subgroupOptions),
          editable: this.canEditSubgroups,
          isLocked: !this.canEditSubgroups,
          validationRules: mustBeOnlySubgroup
            ? { required: true, onlySubgroup: mustBeOnlySubgroup.id }
            : 'required',
          optional: this.canEditSubgroups && !this.inProdromalCohort,
          errors: this.invalidSubgroupError,
          popperClass: 'enrollment-subgroup-dropdown'
        })
      }

      return studyDetailsSection
    },

    /**
     * Determine if a user can edit their cohort.
     * We can't guarantee this value exists so compute the value.
     * @returns {Boolean}
     */
    canEditCohort() {
      return this.participant.canSwitchCohorts || false
    },

    /**
     * Map the available cohorts to dropdown options.
     * Converts a cohort object of { id: '', name: '' } to the accepted format for dropdown menus
     * of { label: '', value: '' }
     * @returns {array} - array of cohorts to use within a dropdown
     */
    cohortsOptions() {
      return this.cohorts.map(cohort => {
        return {
          label: cohort.name,
          value: cohort.id
        }
      })
    },

    /**
     * Should we display the subgroups field?
     * If cohorts are not confirmed or the cohort has no subgroups, do not display.
     * @returns {boolean}
     */
    displaySubgroupsField() {
      return this.cohortHasSubgroups && this.cohortConfirmed
    },

    /**
     * Does the cohort have any subgroups?
     * @returns {boolean}
     */
    cohortHasSubgroups() {
      return this.subgroupOptions.length > 0
    },

    // We need cohortId and subgroupIds accessible as computed props for the substudies query to run
    cohortId() {
      return this.participantWithCalculatedFields.cohortId
    },

    subgroupIds() {
      return this.participantWithCalculatedFields.subgroups
    },

    /**
     * Do the cohort and confirmed cohort match?
     * @returns {boolean}
     */
    cohortConfirmed() {
      return !this.canEditCohort || (this.canEditCohort &&
        this.participantWithCalculatedFields.cohortId === this.participantWithCalculatedFields.confirmCohortId)
    },

    /**
     * Generate a list of subgroup options (if any) for the selected cohort.
     * @returns {array} - an array of subgroup options
     */
    subgroupOptions() {
      if (this.cohorts.length < 1) { return [] }
      return sortBy(
        compose(toUpper, propOr('', 'name')),
        getCohortSubgroups(this.participantWithCalculatedFields.cohortId, this.cohorts)
      )
    },

    /**
     * Determine whether this participant's subgroups can be edited.
     * @returns {boolean}
     */
    canEditSubgroups() {
      // if the cohort is editable and the confirm cohort field does not match
      if (!this.cohortConfirmed) {
        return false
      }
      // if the cohort does not have subgroups
      if (!this.cohortHasSubgroups) {
        return false
      }
      // retreive information about the active visit
      const activeVisit = getActiveVisit(this.visits)
      // if no active visit, there is not enough information to determine if subgroups can be edited.
      if (!activeVisit) {
        return false
      }
      // if the active visit is the baseline visit in a completed state, subgroups cannot be edited.
      if (isBaselineVisit(activeVisit) && activeVisit.status && activeVisit.status === VisitStatus.COMPLETE) {
        return false
      }
      // if the active visit is not the screening or the baseline visit, subgroups cannot be edited.
      return isScreeningVisit(activeVisit) || isBaselineVisit(activeVisit)
    },

    visits() {
      return this.participantVisits?.length ? formatVisits(this.participantVisits) : []
    },

    /**
     * Determine if the participant is associated with the Prodromal cohort.
     * @returns {boolean}
     */
    inProdromalCohort() {
      return this.participant.cohort && isProdromalCohort(this.participant.cohort)
    },

    /**
     * Whether or not the participant has started the Screening visit
     * @returns {boolean}
     */
    hasStartedScreening() {
      const activeVisit = getActiveVisit(this.visits)
      return !isScreeningVisit(activeVisit) || (isScreeningVisit(activeVisit) && activeVisit.inProgress)
    },

    /**
     * Compare the subgroup selection to the saved subgroups for the participant to determine if the selection is valid.
     * @returns {string} - a generated error, if applicable or an empty string if no error exists
     */
    invalidSubgroupError() {
      // only limit subgroup selections if the participant has started the screening visit.
      if (this.canEditSubgroups && this.hasStartedScreening) {
        // compare the schedule weight on the participant to the weight of the modified options
        const savedScheduleWeight = calculateScheduleWeight(
          this.participant.cohort.subgroups,
          this.participant.cohortId,
          this.cohorts
        )
        const selectedScheduleWeight = calculateScheduleWeight(
          this.participantWithCalculatedFields.subgroups,
          this.participantWithCalculatedFields.cohortId,
          this.cohorts
        )
        // if they don't match, generate the error text.
        if (selectedScheduleWeight !== savedScheduleWeight) {
          let acceptedSubgroups, acceptedSubgroupCount
          if (savedScheduleWeight > 0) {
            const acceptedHierarchy = findHierarchyByWeight(
              savedScheduleWeight,
              this.participant.cohortId,
              this.cohorts
            )
            acceptedSubgroups = acceptedHierarchy.subgroups.map(subgroup => subgroup.name)
            acceptedSubgroupCount = acceptedSubgroups.length // store the count before converting array to string.
            if (acceptedSubgroups.length) {
              // modify last element to include 'or' for cleaner error message generation
              if (acceptedSubgroups.length > 1) {
                acceptedSubgroups[acceptedSubgroups.length - 1] =
                  ' or ' + acceptedSubgroups[acceptedSubgroups.length - 1]
              }
              // only add a comma if there are more than 2 subgroups to list.
              acceptedSubgroups = acceptedSubgroupCount <= 2
                ? acceptedSubgroups.join(' ')
                : acceptedSubgroups.join(', ')
            }
          }
          /* There are 2 possible errors that may be shown when schedule weights do not match.
           * Adjust the error based on the following criteria:
           * 1. when the savedScheduleWeight = 0: Must have no subgroups selected.
           * 2. display the valid subgroup options. */
          const errorIntro = savedScheduleWeight === 0 ? 'Must be blank.'
            : acceptedSubgroupCount > 2 ? `Must include ${acceptedSubgroups}.` : `Must be only ${acceptedSubgroups}.`
          return `${errorIntro} Subgroup changes that change a participant’s eligibility criteria are not allowed.`
        }
      }
      return ''
    },

    hasUnsavedChanges() {
      return !equals(
        this.participantWithCalculatedFields,
        this.getInitialParticipantWithCalculatedFields(this.participant)
      )
    }
  },
  watch: {
    participant: {
      deep: true,
      handler(newParticipant) {
        if (!this.loading) {
          // prevents particicipantWithCalcFields from being updated before substudies have been processed
          this.participantWithCalculatedFields = this.getInitialParticipantWithCalculatedFields(newParticipant)
        }
      }
    },

    substudies(newSubstudies, oldSubstudies) {
      if (oldSubstudies.length === 0) {
        this.participantWithCalculatedFields.substudies =
          this.getParticipantSubstudiesFromProtocols(this.participant.protocols)
      }
    },

    // Reset the values for subgroups when the cohort changes
    'participantWithCalculatedFields.cohortId'(newCohort, oldCohort) {
      if (oldCohort && newCohort !== oldCohort) {
        this.participantWithCalculatedFields.subgroups = []
      }
    }
  },
  methods: {
    /**
     * Vee-Validate sometimes returns an invalid state when the form is first loaded, when no errors are recorded.
     * To circumvent this, we count the number of errors to provide a more accurate invalid state.
     * @param {object} errors - an object with keys for each field and an array of errors, if any, for that field
     * @returns {boolean} - true if the form is invalid.
     */
    invalid(errors) {
      const profileErrors = errors ? Object.values(errors).flat() : 0

      // Add a manual check for cohort matching.
      if (this.canEditCohort &&
        this.participantWithCalculatedFields.cohortId !== this.participantWithCalculatedFields.confirmCohortId) {
        profileErrors.push('Invalid cohort')
      }
      // Check if a subgroup error exists
      if (this.invalidSubgroupError) {
        profileErrors.push('Invalid subgroup')
      }
      return profileErrors.length > 0
    },

    /**
     * Initialize a dynamic participant property that tracks form selections with added calculated fields.
     */
    getInitialParticipantWithCalculatedFields(participant) {
      return ({
        ...this.participant,
        confirmCohortId: this.getConfirmCohortId(participant),
        cohort: this.getCohortName(participant.cohortId),
        status: this.getParticipantStatus(participant),
        subgroups: this.getParticipantSubgroups(participant),
        childbearingPotential: this.getParticipantChildbearingPotential(participant),
        substudies: this.getParticipantSubstudiesFromProtocols(participant.protocols)
      })
    },

    /**
     * helper method that returns true or false based on if the participant is of childbearing potential
     * @param {object} participant
     * @returns {boolean}
     */
    getParticipantChildbearingPotential(participant) {
      return participant.sex === 'female_not_of_childbearing_potential'
        ? false : participant.sex === 'female_of_childbearing_potential'
          ? true : ''
    },

    getConfirmCohortId(participant) {
      // get selection or set selected cohort as default value
      return participant.cohortId || ''
    },

    getCohortName(cohortId) {
      if (!cohortId || !this.cohorts) return ''
      const cohort = this.cohorts.find(_cohort => _cohort.id === cohortId)
      return cohort ? cohort.name : ''
    },

    getParticipantStatus(participant) {
      return this.$options.filters.capitalize(
        participant.protocols ? participant.protocols[0].status : ParticipantProtocolStatus.PENDING
      )
    },

    getParticipantSubgroups(participant) {
      return (participant.cohort && participant.cohort.subgroups)
        ? formatSubgroupsForMultiselectValues(this.participant.cohort.subgroups)
        : []
    },

    /**
     * Toggle "editing" mode and initiate the "Save" process when moving from editing -> !editing.
     */
    toggleEditingMode() {
      if (this.editing) {
        this.saveParticipantChanges()
      }
      this.editing = !this.editing
    },

    /**
     * Save updates to the Participant's information.
     */
    async saveParticipantChanges() {
      this.loading = true

      // Format the data on `participantWithCalculatedFields` before initiating the mutation.
      // ensure we send a null value in the absence of an email address
      this.participantWithCalculatedFields.emailAddress = this.participantWithCalculatedFields.emailAddress || null
      this.participantWithCalculatedFields.birthDate = formatDateForAPI(this.participantWithCalculatedFields.birthDate)
      this.participantWithCalculatedFields.gdprConsentDate =
        this.participantWithCalculatedFields.gdprConsentDate
          ? formatDateForAPI(this.participantWithCalculatedFields.gdprConsentDate)
          : null

      if (this.participantWithCalculatedFields.childbearingPotential === false) {
        this.participantWithCalculatedFields.sex = 'female_not_of_childbearing_potential'
      } else if (this.participantWithCalculatedFields.childbearingPotential === true) {
        this.participantWithCalculatedFields.sex = 'female_of_childbearing_potential'
      }

      // we add on childbearingPotential as a helper, its not actually part of a participant (yet)
      try {
        await this.updateParticipant(omit(['childbearingPotential'],
          this.participantWithCalculatedFields))
        await this.updateSubstudies()
        await this.updateCohortsAndSubgroups(this.participantWithCalculatedFields)
        this.loading = false
      } catch (error) {
        logError(error, 'ParticipantProfile.vue edit participant mutation')
      } finally {
        this.loading = false
      }
    },

    updateCohortsAndSubgroups(participant) {
      // check if the Cohort or Subgroups can be updated before running the mutation.
      if (!this.canEditCohort && !this.canEditSubgroups) {
        return
      }
      // extract the ids we need to properly link the participant and cohort, subgroups
      const participantId = propOr('', 'id', participant)
      const cohortId = propOr('', 'cohortId', participant)
      const subgroupIds = propOr([], 'subgroups', participant)
      return this.linkParticipantToCohortAndSubgroups(participantId, cohortId, subgroupIds, { refetchVisits: true })
    },

    /**
     * If there are any unsaved changes, prevent the user from leaving without confirming in a modal.
     */
    navigationGuard(next) {
      if (this.editing && this.hasUnsavedChanges) {
        this.routerNext = next
        this.unsavedChangesModalVisible = true
      } else {
        next()
      }
    },

    returnToProfile() {
      this.unsavedChangesModalVisible = false
    },

    continueWithoutSaving() {
      this.unsavedChangesModalVisible = false
      this.routerNext()
    }
  }
}
</script>

<style lang="scss">
.participant-profile {
  .data-form {
    margin: 1rem 0 2rem;
  }

  .data-form__section,
  .sub-studies {
    padding: 1.5rem 1rem;
    padding-bottom: 0;
    border: 1px solid $axon;
    margin-bottom: 1rem;
    background: $white-matter;
  }

  .sub-studies {
    padding-bottom: 1.5rem;
    border-top: none;
    /* create the illusion of 1 card */
    margin-top: calc(-2rem - 2px);

    h3 {
      margin: 0;
      @include text-style('title', 'medium', 'medium');
    }
  }
}
</style>
