import LINK_PARTICIPANT_TO_COHORT_MUTATION from '@/graphql/participants/LinkParticipantToCohortMutation.graphql'
import GET_PARTICIPANT_VISITS_QUERY from '@/graphql/participants/GetStudyParticipantVisitsQuery.graphql'
import GET_SITE_PARTICIPANTS_QUERY from '@/graphql/participants/GetSiteParticipantsQuery.graphql'

export default {
  methods: {
    /**
     * Link the participant to a cohort and/or enrollment subgroups.
     * @param {string} participantId - the id of the participant to link
     * @param {string} cohortId - the id of the cohort to link the participant to
     * @param {array} subgroupIds - an array of subgroup ids to link the participant to
     * @param {object} options - an optional object for additional options. Includes toggles for refetching queries
     * @returns {Promise}
     * TODO: wire up to participant enrollment? Built to support it.
     */
    linkParticipantToCohortAndSubgroups(
      participantId,
      cohortId,
      subgroupIds,
      options = { refetchParticipants: true, refetchVisits: false }
    ) {
      const refetchQueries = []
      if (options.refetchParticipants) {
        // During enrollment we don't have enough info about the participant (like their visit schedule) to fill in all
        // the information directly for this query, so re-fetch it as soon as the participant is fully linked up.
        refetchQueries.push({
          query: GET_SITE_PARTICIPANTS_QUERY,
          variables: {
            studyId: this.$route.params.studyId,
            siteId: this.newParticipant.siteId
          }
        })
      }
      if (options.refetchVisits) {
        refetchQueries.push({
          query: GET_PARTICIPANT_VISITS_QUERY,
          variables: {
            participantId: this.$route.params.participantId,
            studyId: this.$route.params.studyId
          }
        })
      }
      return this.$apollo.mutate({
        mutation: LINK_PARTICIPANT_TO_COHORT_MUTATION,
        variables: {
          participantId: participantId,
          cohortId: cohortId,
          subgroupIds: subgroupIds
        },
        // refetch the chosen queries
        refetchQueries
      })
    }
  }
}
